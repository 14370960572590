<template>
  <div>
    <NavBar :user="user" v-if="initialized" :key="user ? user.id : 'none'"></NavBar>
    <div class="container-fluid">
      <router-view :key="$route.path" v-if="initialized" />
      <div class="d-flex align-items-center justify-content-center" v-if="!initialized">
        <i class="fa fas fa-spin fa-spinner" style="font-size: 2rem;"></i>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.fullscreen {
  height: 100vh;
}
</style>

<script>
import NavBar from '@/components/NavBar';


export default {
  name: 'App',
  components: {NavBar},
  mounted () {
    this.checkExistingCredentials().then(() => {
      this.$router.appInitialized = true;
      this.$router.replace(this.$router.initialRoute);
      this.initialized = true;
      this.$forceUpdate();
    });
  },
  data() {
    return {
      initialized: false,
      user: null
    };
  },
  created() {
    // Parse data attributes on tag
  },
  methods: {
    checkExistingCredentials: function() {
        return new Promise((resolve) => {
          try {
            this.$api.getCurrentUser().then((res) => {
                this.setUser(res.data);
                resolve();
            }).catch(() => {
              console.warn('Failed to authenticate');
              localStorage.user = null;
              resolve();
            });

          } catch {
            resolve();
          }
        });
      },
      setUser: function(user) {
        this.user = user ? user : null;
        this.emitter.emit('userChanged', this.user);
        this.$router.currentUser = this.user;
        this.$api.currentUser = this.user;
      }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
