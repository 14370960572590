<template>
  <div class="d-flex justify-content-center">
    <div class="form standalone activationform">
      <StatusMessage ref="statusMessage"></StatusMessage>
      <form v-on:submit.prevent="activatePayment($event)">
        <h1 v-if="record.id && record.subscription_status === 'Inactive'">{{ $t.get('Activate Subscription') }}</h1>
        <h1 v-if="record.id && record.subscription_status !== 'Inactive'">{{ $t.get('Subscription') }}</h1>

        <div class="form-group row">
          <label for="name" class="col-sm-4 col-form-label">{{ $t.get('Name') }}</label>
          <div class="col-sm-8 col">
            <input type="text" readonly class="form-control-plaintext" id="name" :value="record.name">
          </div>
        </div>

        <div class="form-group row">
          <label for="paymentInterval" class="col-sm-4 col-form-label">{{ $t.get('Payment Interval') }}</label>
          <div class="col-sm-8 col">
            <input type="text" readonly class="form-control-plaintext" id="paymentInterval" :value="record.subscription_payment_interval">
          </div>
        </div>

        <div class="form-group row">
          <label for="price" class="col-sm-4 col-form-label">{{ $t.get('Price') }}</label>
          <div class="col-sm-8 col">
            <input type="text" readonly class="form-control-plaintext" id="price" :value="record.price">
          </div>
        </div>

        <div class="form-group row">
          <label for="setupFee" class="col-sm-4 col-form-label">{{ $t.get('Setup Fee') }}</label>
          <div class="col-sm-8 col">
            <input type="text" readonly class="form-control-plaintext" id="setupFee" :value="record.setup_fee">
          </div>
        </div>

        <div class="text-center buttons">
          <button v-if="record.subscription_status === 'Inactive'" type="submit" :name="'activate-payment'" :id="'activate-payment'" class="btn btn-primary" :disabled="processing || disableActivate">
            <i class="fas" :class="{'fa-credit-card': !this.processing, 'fa-spin fa-spinner': this.processing}"></i>
            {{ $t.get('Activate recurring payment with Vipps') }}
          </button>
          <span v-if="record.subscription_status === 'Active'">
            {{ $t.get('This subscription is already active') }}
          </span>
          <span v-if="record.subscription_status === 'Pending'">
            {{ $t.get('This subscription is being processed') }}
          </span>
          <span v-if="['Inactive', 'Active', 'Pending'].indexOf(record.subscription_status) === -1">
            {{ $t.get('This subscription cannot be reactivated') }}
          </span>
        </div>
      </form>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.activationform {
  width: 100%;
  max-width: 600px;
  margin-top: 40px;

  label {
    background: $pallete-e;
    color: $color-light-text;
    text-align: right;
  }

  .row {
    margin-bottom: 4px;
  }

  .col {
    border: 1px solid $pallete-e;
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;
    padding: 8px 4px;
  }
}
.errorlist {
  list-style: none;
  padding: 0px; margin: 0px;
}
@media (min-width: 576px) {
  .activationform {
    width: 50%;

  }
}
</style>

<script>

import StatusMessage from "@/components/StatusMessage";

export default {
  components: {
    StatusMessage
  },
  props: ['id'],
  data: function() {
    return {
      record: {},
      processing: false,
      disableActivate: true,
    };
  },
  created: function() {
  },
  mounted: function() {
    this.getRecord();
  },
  methods: {
    getRecord: function() {
      this.$api.get(`/customer/subscriptions/${this.id}`).then((res) => {
        this.record = res.data
        this.disableActivate = this.record.status !== 'I';
      }).catch((err) => {
        console.error(err);
        this.$refs.statusMessage.set('danger', this.$t.get('An error occurred whilst loading this record.  Click here to try again.'), () => {
          this.getRecord();
        });
      });
    },
    activatePayment: function () {
      this.$api.post(`/customer/subscriptions/${this.id}/activate_payment/`).then((res) => {
        window.location.href = res.data.redirect_url;
      }).catch((err) => {
        console.error(err);
        this.$refs.statusMessage.set('danger', this.$t.get('An error occurred whilst activating this subscription.  Click here to try again.'), () => {
          this.activatePayment();
        });
      });
    }
  }
}
</script>
