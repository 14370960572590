<template>
  <div v-if="this.user">
    <nav class="navbar navbar-expand-xl navbar-dark">
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand">
          <img src="@/assets/images/monsternett-logo.png" />
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <!-- <li class="nav-item"><router-link :to="{name: 'Subscriptions'}" class="nav-link">{{ $t.get('Subscriptions') }}</router-link></li> -->
          </ul>
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
            <li class="nav-item">
              <span class="small username">{{ this.user.name }}</span>
            </li>
            <li class="nav-item">
              <a v-on:click.prevent="logout()" href="#" :title="$t.get('Log out')"><i class="fa fas fa-sign-out"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="scss">
.navbar {
  background: $pallete-e;
  margin-bottom: 20px;

  .username {
    color: $color-light-text;
    margin-right: 40px;
  }
  a {
    color: $color-light-text;
  }

  .navbar-brand {
    padding: 0px;
    img {
      max-height: 60px;
    }
  }
}
.navbar-collapse {
  position: absolute;
  background: $pallete-e;
  top: 56px;
  right: 0px;
  width: 50%;
  box-sizing: border-box;
  padding: 8px;
  z-index: 10;
}
@media (min-width: 576px) {

}
@media (min-width: 1200px) {
  .navbar-collapse {
    position: static;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    width: 300px;
    left: calc(100% - 300px);
  }
}
</style>

<script>
import { nextTick } from 'vue';

export default {
  props: ['user'],
  mounted: function() {
    this.emitter.on('userChanged', () => {
      nextTick(() => {
        this.$forceUpdate();
      });
    });
  },
  methods: {
    logout: function() {
      this.emitter.emit('authenticated', {
        token: null,
        user: null
      });
       window.location.href =  this.$api.logOutUrl;
    }
  }
}
</script>
