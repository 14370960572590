<template>
  <div class="alert"
    :class="'alert-' + message.type"
    v-if="message != null"
    v-on:click.prevent="onClick()"
    v-html="message.message">
  </div>
</template>

<style scoped>
.alert {
  cursor: pointer;
}
</style>

<script>
export default {
  name: 'StatusMessage',
  data: function() {
    return {
      message: null,
      callback: null
    };
  },
  methods: {
    clear: function() {
      this.message = null;
    },
    set: function(type, message, callback) {
      this.message = {
        type,
        message
      };
      this.callback = callback ? callback : null;
    },
    onClick: function() {
      if (this.callback) {
        this.callback();
      }
    }
  }
};
</script>
