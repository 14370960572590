<template>
  <div>
    <h1 v-if="status !== 'C'">{{ $t.get('Subscription Activated') }}</h1>
    <h1 v-else>{{ $t.get('Subscription Cancelled') }}</h1>
    
    <div v-if="status === null || status === 'P'">
      <p>{{ $t.get('Thank you for activating your subscription, please wait while we set things up.') }}</p>
      <p class="small">{{ $t.get('This may take a few moments.') }}</p>
    </div>

    <div v-if="status === 'A'">
      <p>{{  $t.get('Your subscription is active and ready to use.') }}</p>
    </div>

    <div v-if="status === 'I'">
      <p>{{  $t.get('This subscription has not yet been activated') }}</p>
    </div>

    <div v-if="status === 'C'">
      <p>{{  $t.get('This subscription has been cancelled and cannot be reactivated') }}</p>
    </div>

    <StatusMessage ref="statusMessage"></StatusMessage>
    <div class="spinner" v-if="!hasError">
      <i class="fa" :class="{
        'fa-spinner fa-spin': status === null || status === 'P',
        'fa-check-circle green': status === 'A',
        'fa-exclamation-circle orange': status === 'I',
        'fa-exclamation-triangle red': status === 'C'
      }"></i>
    </div>

    <div style="margin-top: 20px;" v-if="status !== null && status !== 'P'">
      <p v-if="status !== 'I'"><router-link :to="{'name': 'Home'}" >{{ $t.get('Click here to return to the dashboard') }}</router-link></p>
      <p v-if="status === 'I'">
        <router-link :to="{'name': 'SubscriptionPaymentActivation', 'params': {'id': $route.params.id}}">
          {{ $t.get('Click here to activate it') }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.spinner i {
  font-size: 4rem;
}
.spinner {
  .green {
    color: green;
  }
  .orange {
    color: orange;
  }
  .yellow {
    color: rgb(128, 128, 0);
  }
  .red {
    color: red;
  }
}
</style>

<script>
import StatusMessage from '@/components/StatusMessage.vue';

export default {
  components: {
    StatusMessage
  },
  mounted() {
    this.update();
  },
  data() {
    return {
      status: null,
      hasError: false
    };
  },
  methods: {
    update() {
      this.hasError = false;
      this.$refs.statusMessage.clear();
      this.$api.get('/customer/subscriptions/' + this.$route.params.id).then((response) => {
        this.status = response.data.status;
        if (this.status === 'P') {
          setTimeout(() => {
            this.update();
          }, 1000);
        }
      }).catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({name: 'Home'});
          return;
        }
        this.hasError = true;
        console.error(error);
        this.$refs.statusMessage.set('danger', this.$t.get('An error occurred whilst checking the status of this subscription.  Click here to try again.'), () => {
          this.update();
        })
      });
    }
  }
}
</script>