export default {
    loginUrl: null,
    logoutUrl: null,
    apiUrl: null,  

    install(app) {
      this.created();
      app.config.globalProperties.$consts = this;
    },
    created() {
        const e = document.getElementById('app');
        if (e) {
          this.loginUrl = e.getAttribute('data-login-url');
          this.logoutUrl = e.getAttribute('data-logout-url');
          this.apiUrl = this.trimTrailingSlash(e.getAttribute('data-api-url'));
        }
    },
    trimTrailingSlash(str) {
      if (str.substr(-1) === '/') {
        return str.substr(0, str.length - 1);
      }
      return str;
    }
};  