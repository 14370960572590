<template>
  <div>
    <h1>{{ $t.get('Your Subscriptions') }}</h1>
    <StatusMessage ref="statusMessage"></StatusMessage>
    <AdminTable :records="records" :fields="getFields()"
      :additionalRoutes="{
        'results': {
          'label': $t.get('Activate'),
          'route': getPaymentActivationRoute,
          'icon': 'fa-credit-card',
          'if': (rec) => { return rec.subscription_status === 'Inactive' }
        }
      }"></AdminTable>
  </div>
</template>

<script>
import AdminTable from '@/components/AdminTable'
import StatusMessage from '@/components/StatusMessage'

export default {
  components: {
    AdminTable,
    StatusMessage
  },
  data: function() {
    return {
      records: [],
      page: 0,
    };
  },
  props: [],
  mounted: function() {
    this.updateList();
  },
  methods: {
    getFields() {
      return [{id: 'name', label: this.$t.get('Name')}, {id: 'subscription_status', label: this.$t.get('Status')}, {id: 'subscription_payment_interval', label: this.$t.get('Payment Interval')}, {id: 'price', label: this.$t.get('Price')}, {id: 'setup_fee', label: this.$t.get('Setup Fee')}, {id: 'subscription_service_type', label: this.$t.get('Service Type')}]
    },
    updateList: function() {
      const ts = new Date().getTime();
      this.$refs.statusMessage.clear();

      this.$api.get(`/customer/subscriptions/?ts=${ts}`).then((res) => {
        if (res.data.ts !== ts) {
          return;
        }
        this.records = res.data.results;
        this.checkForWarnings();
      }).catch((err) => {
        console.error(err);
        this.$refs.statusMessage.set('danger', this.$t.get('An error occurred whilst loading this list.  Click here to try again.'), () => {
          this.updateList();
        });
      });
    },
    getDeleteUrl: function() {
      return {}
    },
    getEditRoute: function() {
      return {}
    },
    getCreateRoute: function() {
      return {}
    },
    getPaymentActivationRoute: function(rec) {
      return {
        name: 'SubscriptionPaymentActivation',
        params: {
          id: rec.id
        }
      }
    },
    checkForWarnings: function() {
      let warnings = [];
      for (let record of this.records){
        if (record.warnings !== null) {
          warnings.push(record.warnings)
        }
        if (warnings.length > 0) {
          this.$refs.statusMessage.set('danger', this.$t.get(warnings.join('\r\n')), () => {});
        }
      }
    }
  }
}
</script>

<style>

</style>
