<template>
  <div>
    <table class="table admin-table" v-if="records">
      <thead>
        <tr>
          <th v-for="(field, index) in fields" :key="index">{{ formatFieldName(field) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(rec, index) in records" :key="'rec-' + index">
          <td v-for="(field, fieldIndex) in fields" :key="'rec-' + index + '-' + fieldIndex">
            <span v-if="!getFieldType(field)">{{ formatFieldValue(field, rec) }}</span>
            <span class="bool" v-if="getFieldType(field) === 'bool'">
              <i class="fa fas" :class="{'fa-check-circle': getFieldValue(field, rec), 'fa-check-times': !getFieldValue(field, rec)}"></i>
            </span>
          </td>
          <td v-if="deleteUrl || editRoute || additionalRoutes" class="actions">
            <router-link class="btn btn-sm btn-secondary" v-if="editRoute && editRoute(rec)" :to="editRoute(rec)"><i class="fa fas fa-edit"></i></router-link>
            <button class="btn btn-sm btn-danger" v-if="deleteUrl && deleteUrl(rec)" v-on:click.prevent="deleteRec(deleteUrl(rec))"><i class="fa fas fa-trash"></i></button>
            <span v-if="additionalRoutes" style="margin-left: 20px">
              <router-link v-show="additionalRoutes[route].if ? additionalRoutes[route].if(rec) : true" class="btn btn-sm btn-secondary" v-for="route of Object.keys(additionalRoutes)" :key="'route-' + route" :to="additionalRoutes[route].route(rec)">
                <i class="fa fas" v-if="additionalRoutes[route].icon" :class="additionalRoutes[route].icon"></i>
                <span v-if="additionalRoutes[route].label" v-html="additionalRoutes[route].label"></span>
              </router-link>
            </span>
          </td>
        </tr>
        <tr v-if="records.length === 0">
          <td colspan="99" class="text-center" style="opacity: 0.6;">
            {{ $t.get('No records found') }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-right">
      <router-link class="btn btn-sm btn-primary" v-if="createRoute" :to="createRoute()"><i class="fa fas fa-plus"></i></router-link>
    </div>
  </div>
</template>

<style lang="scss">
  .table {
    .actions {
      border: none;

      .btn i {
        padding: 0px 4px;
      }
    }
  }
</style>

<script>
export default {
  props: ['records', 'fields', 'deleteUrl', 'editRoute', 'createRoute', 'additionalRoutes'],
  methods: {
    getFieldId: function(field) {
      return field.id ? field.id : field;
    },
    formatFieldName: function(field) {
      if (field.label) {
        return field.label;
      }
      const fieldId = this.getFieldId(field);
      return fieldId.split('_').join(' ');
    },
    getFieldType: function(field) {
      return field.type ? field.type : null;
    },
    getFieldValue: function(field, rec) {
      return rec[this.getFieldId(field)];
    },
    formatFieldValue: function(field, rec) {
      const val = this.getFieldValue(field, rec)
      return val;
    },
    deleteRec: function(url) {
      if (!window.confirm(this.$t.get('Are you sure you want to delete this record?'))) {
        return false;
      }
      
      this.$api.delete(url).then(() => {
        this.$toasted.show(this.$t.get('Record deleted'), {type: 'success'});
        this.update();
      }).catch((err) => {
        if (err.response.status === 400 && err.response.data.non_field_errors) {
          this.$toasted.show(err.response.data.non_field_errors[0], {type: 'error'});
        } else {
          this.$toasted.show(this.$t.get('Failed to delete the requested record.  Please check your connection and try again.'), {type: 'error'});
        }
        console.error(err);
      })
    },
    update: function() {
      if (this.$parent && this.$parent.updateList) {
        this.$parent.updateList();
      }
    }
  }
}
</script>