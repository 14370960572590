import { createRouter as createVueRouter, createWebHistory } from 'vue-router'
import bootstrap from 'bootstrap/dist/js/bootstrap.js'

import LoginView from '@/views/LoginView.vue'
import TermsView from '@/views/TermsView.vue'
import SubscriptionList from '@/views/SubscriptionList.vue'
import SubscriptionPaymentActivation from '@/views/SubscriptionPaymentActivation.vue'
import SubscriptionActivatedView from '@/views/SubscriptionActivatedView.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: {
      name: 'Subscriptions'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsView
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: SubscriptionList,
  },
  {
    path: '/subscription/:id/activate',
    name: 'SubscriptionPaymentActivation',
    component: SubscriptionPaymentActivation,
    props: true,
  },
  {
    path: '/subscription/:id/activated',
    name: 'SubscriptionActivatedView',
    component: SubscriptionActivatedView,
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'CatchAll',
    redirect: {
      name: 'Home'
    }
  },
]

export const createRouter = (app) => {
  const router = createVueRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    mode: 'abstract',
  })
  router.appInitialized = false;
  router.initialRoute = null;

  router.beforeEach((to) => {
    if (!router.initialRoute) {
      router.initialRoute = JSON.parse(JSON.stringify(to));
    }
    if (!router.appInitialized) {
      return false;
    }

    if ((!app.config.globalProperties.$api.isAuthenticated() || !router.currentUser) && (to.name !== 'Login' && to.name !== 'Terms')) {
      return {name: 'Login'};
    } else {
      const currentView = app.getCurrentView ? app.getCurrentView() : null;
      if (currentView && currentView.canLeave !== undefined && !currentView.canLeave()) {
        if (!confirm(app.config.globalProperties.$t.get('You have unsaved changes!  Are you sure you want to discard them?'))) {
          return false;
        } else {
          return true;
        }
      }
    }


    return true;
  })

  router.afterEach(() => {
    const menuToggle = document.getElementById('navbarSupportedContent')
    if (!menuToggle) {
      return;
    }
    const bsCollapse = bootstrap.Collapse.getOrCreateInstance(menuToggle, {
      toggle:false
    })
    bsCollapse.hide();
  });

  return router;
}
