import axios from "axios";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

export default {
  authToken: null,
  apiUrl: null,

  install(app) {
    this.created();

    this.apiUrl = app.config.globalProperties.$consts.apiUrl;
    this.logOutUrl = app.config.globalProperties.$consts.logoutUrl;

    app.config.globalProperties.$api = this;
  },
  data: {
    client: null,
    currentUser: null,
    organizationId: null,
    apiUrl: null,
    initialized: false,
    logOutUrl: null,
  },
  created: function() {
    this.updateClient();
  },
  updateClient: function() {
    const headers = {};
    this.client = axios.create({
      headers
    });
  },

  post: function(path, data, config) {
    return this.client.post(this.apiUrl + path, data === undefined ? {} : data, config !== undefined ? config : config);
  },
  put: function(path, data) {
    window.console.warning('Put used for ' + path + ' .. did you mean patch?');
    return this.client.put(this.apiUrl + path, data === undefined ? {} : data);
  },
  patch: function(path, data, config) {
    return this.client.patch(this.apiUrl + path, data === undefined ? {} : data, config !== undefined ? config : config);
  },
  delete: function(path, data) {
    return this.client.delete(this.apiUrl + path, data === undefined ? {} : data);
  },
  get: function(path, data) {
    return this.client.get(this.apiUrl + path, {
      params: data === undefined ? {} : data
    });
  },
  // Authentication and stored tokens
  isAuthenticated: function() {
    return this.currentUser != null;
  },
  getCurrentUser() {
    return this.client.get(this.apiUrl + '/account/user/current/');
  }
}
