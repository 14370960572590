<template>
  <div class="d-flex flex-column justify-content-center align-items-center fullscreen">
    <div class="form standalone loginform">
      <img class="logo" src="@/assets/images/monsternett-logo.png" />
      <StatusMessage ref="statusMessage"></StatusMessage>
      <div>
        <a :href="loginUrl"><img src="../assets/images/log_in_with_vipps_rect_210_NO.png" id="login-logo"></a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loginform {
  background-color: #1d233c;
  padding: 10px 40px;
  border-radius: 10px;
  display: inline-block;
  color: #fff;
  box-shadow: 4px 4px 10px 0px rgba(0,0,0,0.75);
}
.logo {
  margin-bottom: 40px;
}
#login-logo {
  max-width: 500px;
  // width: 30%;
  margin-bottom: 10px;
}
</style>

<script>
import StatusMessage from '@/components/StatusMessage.vue'

export default {
  components: {
    StatusMessage
  },
  mounted() {
    if (this.$api.isAuthenticated()) {
      this.$router.push({ name: 'Home' });
    }
  },
  data: function() {
    return {
      processing: false,
      username: '',
      password: '',
      loginUrl: this.$consts.loginUrl
    }
  },
  methods: {
  }
}
</script>
