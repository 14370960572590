import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import { createApp } from 'vue'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import Toasted from 'vue-toasted'
import mitt from 'mitt'

import ConstantService from './services/ConstantService.js';
import ApiService from './services/ApiService.js';
import TranslationService from "./services/TranslationService";
import {createRouter} from "@/router";

const emitter = mitt()
const app = createApp(App)
app.config.globalProperties.emitter = emitter
// Toasted workaround
const prevPrototype = app.prototype

app.prototype = {}
app.use(Toasted, {
  duration: 5000,
  position: 'top-right'
})

Object.assign(app.config.globalProperties, app.prototype)

app.prototype = prevPrototype

app.use(VueAxios, axios).use(ConstantService).use(ApiService).use(TranslationService)
const router = createRouter(app)

if (process.env.VUE_APP_ENABLE_SENTRY === '1') {
  console.log('Enabling Sentry');
  Sentry.init({
    app,
    dsn: "https://ed4742835ead4a9c9f8c4a58a8450745@o143899.ingest.sentry.io/6248998",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "mathsquiz.software13.net", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true
  });
}

app.use(router)
app.mount('#app')
